import { useSelector } from "react-redux";
import { InfoComp, InfoMain, InfoCOntact } from "../../assets/data";
import React from "react";
import { Form, Formik } from "formik";
import { request } from "../../api/request";
import axios from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
const Ermerp = () => {
  const lang = useSelector((state) => state.data.lang);
  const validation = Yup.object().shape({
    organization_name: Yup.string().required("Required"),
    position: Yup.string().required("Required"),
    name_work: Yup.string().min(20, "Too Short!").required("Required"),
    sales: Yup.string().required("Required"),
    clients: Yup.string().required("Required"),
    market: Yup.string().required("Required"),
    system: Yup.string().required("Required"),
    number_of_employees: Yup.string().required("Required"),
    departments: Yup.string().required("Required"),
    total_number_of_offices: Yup.string().required("Required"),
    location_of_offices: Yup.string().required("Required"),
    main_functionality: Yup.string().required("Required"),
    crm_data: Yup.string().required("Required"),
    urgently_solve_system: Yup.string().required("Required"),
    which_one: Yup.string().required("Required"),
    problem: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone_number: Yup.string().required("Required"),
    company_website: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          organization_name: "",
          position: "",
          name_work: "",
          sales: "",
          clients: "",
          market: "",
          system: "",
          number_of_employees: "",
          departments: "",
          total_number_of_offices: "",
          location_of_offices: "",
          main_functionality: "",
          crm_data: "",
          urgently_solve_system: "",
          which_one: "",
          problem: "",
          email: "",
          phone_number: "",
          company_website: "",
        }}
        validationSchema={validation}
        onSubmit={(values) => {
          const formdata = new FormData();
          Object.keys(values).forEach((elem) => {
            formdata.append(elem, values[elem]);
          });
          toast.promise(
            axios.post(
                "https://script.google.com/macros/s/AKfycbxXVRtMZeOj36jYFVp51Jlc5tA-4hZyZUW5HP_yuCUSfMDZEEOEX83LMsLIG1n7K0ks/exec",
                formdata,
                { headers: { "Content-Type": "multipart/form-data" } }
              ),
              {
                pending: 'Request is pending...',
                success: 'Data posted successfully!',
                error: 'Error fetching data!'
              }
          )
        }}
      >
        {({  setFieldValue, onSubmit, errors }) => {
          return (
            <Form>
              <div className="ticket_block_botton">
                <h2 className="ticket_block_bottom_title">
                  {InfoComp[lang]?.title} *
                </h2>
                {InfoComp[lang]?.fields?.map((elem, i) => (
                  <React.Fragment key={i}>
                    <br />
                    <p className="ticket_block_bottom_description">
                      {elem?.title}
                    </p>
                    <br />
                    {i === 2 ? (
                      <>
                        <textarea
                          rows="4"
                          type="text"
                          name={elem?.name}
                          onChange={(e) =>
                            setFieldValue(elem?.name, e.target.value)
                          }
                          placeholder={elem?.placeholder}
                          className="ticket_block_bottom_input"
                        />
                        <p className="error">
                          {errors[elem?.name] && errors[elem?.name]}
                        </p>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          name={elem?.name}
                          onChange={(e) =>
                            setFieldValue(elem?.name, e.target.value)
                          }
                          placeholder={elem?.placeholder}
                          className="ticket_block_bottom_input"
                        />
                        <p className="error">
                          {errors[elem?.name] && errors[elem?.name]}
                        </p>
                      </>
                    )}
                    <br />
                  </React.Fragment>
                ))}
              </div>
              {InfoMain[lang]?.map((elem, i) => (
                <div className="ticket_block_botton" key={i}>
                  <br />
                  <p className="ticket_block_bottom_description">
                    {elem?.title}
                  </p>
                  <p className="error">
                    {errors[elem?.name] && errors[elem?.name]}
                  </p>
                  <p className="ticket_description">
                    <i>{elem?.desc}</i>
                  </p>
                  <br />
                  {elem?.fields?.map((item, j) => (
                    <>
                      <div className="ticket_block_botton_inner" key={j}>
                        <input
                          id={`check${item}`}
                          value={item}
                          type="radio"
                          onChange={(e) =>
                            setFieldValue(elem?.name, e.target.value)
                          }
                          name={elem?.name}
                        />
                        <label htmlFor={`check${item}`}>{item}</label>
                      </div>
                    </>
                  ))}
                  {elem?.input && (
                    <React.Fragment>
                      <br />
                      <p className="ticket_block_bottom_description">
                        {elem?.input?.title} *
                      </p>
                      <br />
                      <input
                        type="text"
                        onChange={(e) =>
                          setFieldValue(elem?.input?.name, e.target.value)
                        }
                        placeholder={elem?.input?.placeholder}
                        className="ticket_block_bottom_input"
                      />
                      <p className="error">
                        {errors[elem?.input?.name] && errors[elem?.input?.name]}
                      </p>
                    </React.Fragment>
                  )}
                </div>
              ))}
              {InfoCOntact[lang]?.map((elem, i) => (
                <div className="ticket_block_botton" key={i}>
                  <h2 className="ticket_block_bottom_title">{elem?.title} *</h2>
                  <br />
                  {elem?.fields?.map((item, j) => (
                    <React.Fragment key={j}>
                      <p className="ticket_block_bottom_description">
                        {item?.title}
                      </p>
                      <br />
                      <input
                        type="text"
                        onChange={(e) =>
                          setFieldValue(item?.name, e.target.value)
                        }
                        placeholder={item?.placeholder}
                        className="ticket_block_bottom_input"
                        name={item?.name}
                      />
                      <p className="error">
                        {errors[item?.name] && errors[item?.name]}
                      </p>
                    </React.Fragment>
                  ))}
                </div>
              ))}
              <button onClick={onSubmit} className="tickent_btn">
                {lang == "en"
                  ? "Send"
                  : lang == "ru"
                  ? "Отправить"
                  : "Yuborish"}
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Ermerp;
