import { useSelector } from "react-redux";
import { InfoCompFree } from "../../assets/data";
import React from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import * as Yup from "yup";
const Free = () => {
  // Get the current language from the Redux store
  const lang = useSelector((state) => state.data.lang);
  const validation = Yup.object().shape({
    name: Yup.string().required("Required"),
    phone_number: Yup.number().required("Required"),
    desc: Yup.string().required("Required"),
  });
  
  return (
    <div>
      <Formik
        initialValues={{
          desc: "",
          phone_number: "",
          name: "",
        }}
        validationSchema={validation}
        onSubmit={(values) => {
          const formdata = new FormData();
          Object.keys(values).forEach((elem) => {
            formdata.append(elem, values[elem]);
          });
          toast.promise(
            axios.post(
              "https://script.google.com/macros/s/AKfycbxdeb2ZqtvuMeelmfIXl0hWa8TNMpwTZwW0Kf5TmoihfzXkwLyjEDoJ6n0bUjC9JTms/exec",
              formdata,
              { headers: { "Content-Type": "multipart/form-data" } }
            ),
            {
              pending: "Request is pending...",
              success: "Data posted successfully!",
              error: "Error fetching data!",
            }
          );
        }}
      >
        {({ setFieldValue, errors, onSubmit }) => {
          return (
            <Form>
              <div className="ticket_block_botton">
                {InfoCompFree[lang]?.fields?.map((elem, i) => (
                  <React.Fragment key={i}>
                    <br />
                    <p className="ticket_block_bottom_description">
                      {elem?.title}
                    </p>
                    <br />
                    <>
                      {i === 2 ? (
                        <>
                          <textarea
                            rows="4"
                            type="text"
                            name={elem?.name}
                            onChange={(e) =>
                              setFieldValue(elem?.name, e.target.value)
                            }
                            placeholder={elem?.placeholder}
                            className="ticket_block_bottom_input"
                          />
                          <p className="error">
                            {errors[elem?.name] && errors[elem?.name]}
                          </p>
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            name={elem?.name}
                            onChange={(e) =>
                              setFieldValue(elem?.name, e.target.value)
                            }
                            placeholder={elem?.placeholder}
                            className="ticket_block_bottom_input"
                          />
                          <p className="error">
                            {errors[elem?.name] && errors[elem?.name]}
                          </p>
                        </>
                      )}
                    </>

                    <br />
                  </React.Fragment>
                ))}
              </div>
              <button onClick={onSubmit} className="tickent_btn">
                {lang == "en"
                  ? "Send"
                  : lang == "ru"
                  ? "Отправить"
                  : "Yuborish"}
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Free;
