export const InfoComp = {
  en: {
    title: "Information about the organization",
    fields: [
      {
        title: "Organization name",
        name:'organization_name',
        placeholder: "Specify the organization",
      },
      {
        title: "Your position in the company",
        name:'position',
        placeholder: "Specify the position",
      },
      {
        title:
          "Write the main areas of work of your organization, what it does (more in free form)",
          name:'name_work',
        placeholder: "Specify the main areas of activity of the organization",
      },
    ],
  },
  uz: {
    title: "Tashkilot haqida ma'lumot",
    fields: [
      {
        title: "Tashkilot nomi",
        name:'organization_name',
        placeholder: "Tashkilotingizni ko'rsating",
      },
      {
        title: "Sizning kompaniyadagi mavqeingiz",
        name:'position',
        placeholder: "O'z pozitsiyangizni ko'rsating",
      },
      {
        title:
          "Tashkilotingiz faoliyatining asosiy yo'nalishlarini yozing, u nima qiladi (batafsilroq ma'lumot bepul shaklda)",
          name:'name_work',
        placeholder:
          "Tashkilot faoliyatining asosiy yo'nalishlarini ko'rsating",
      },
    ],
  },
  ru: {
    title: "Информация об организации",
    fields: [
      {
        title: "Название организации",
        name:'organization_name',
        placeholder: "Укажите организацию",
      },
      {
        title: "Ваша должность в компании",
        name:'position',
        placeholder: "Укажите должность",
      },
      {
        title:
          "Напишите основные направления работы Вашей организации, чем она занимается (подробнее в свободной форме)",
          name:'name_work',
        placeholder: "Укажите основные направления деятельности организации",
      },
    ],
  },
};
export const InfoMain = {
  en: [
    {
      title: "The main part of your sales is wholesale or retail",
      name: "sales",
      fields: [
        "Wholesale",
        "Retail",
        "Wholesale/Retail",
        "Provision of services",
        "Working with projects",
      ],
    },
    {
      title: "Main clients of your company",
      name: "clients",
      fields: ["Individuals - B2C", "Legal entities - B2B", "Both directions"],
    },
    {
      title: "How many years has your company been on the market?",
      name: "market",
      fields: ["0-6 months", "Up to 2 years", "2-5 years", "More than 5 years"],
    },
    {
      title: "Do you have any CRM or ERP system implemented at the moment?",
      name: "system",
      desc: "For example: 1C, Bitrix and others",
      fields: ["Yes", "No"],
      input: {
        title: "If yes, which one?",
        name:'which_one',
        placeholder: "Specify the type and category of the platform",
      },
    },
    {
      title:
        "How many employees of the company will use the CRM system, taking into account the management of the company?",
      desc: "The functionality and complexity of the tasks performed by the system depends on the number of users",
      name: "number_of_employees",
      fields: ["1 - 5", "5 - 20", "20 - 50", "50 - 100", "100+"],
    },
    {
      title: "Which departments of your company will use the CRM system?",
      name: "departments",
      fields: [
        "Organization management",
        "Sales department",
        "HR department",
        "Finance department, accounting",
        "Legal department",
        "Marketing department",
        "Development department",
        "System administration department",
        "Production complex",
        "Supply and logistics department",
        "Analytics department",
        "Quality monitoring department",
      ],
    },
    {
      title:
        "What is the total number of offices, warehouses, stores, production workshops where company employees work who will use the CRM system?",
      name: "total_number_of_offices",
      fields: ["There's no any", "1-2", "2-5", "5-15", "15+"],
    },
    {
      title:
        "Location of offices, warehouses, stores, production facilities where the company employees who will use the CRM system work",
      desc: "Indicate in which cities/countries the company's offices/divisions are located",
      name: "location_of_offices",
      fields: ["In one city", "In different cities", "In different countries"],
    },
    {
      title:
        "Select from the list the main functionality that a CRM system should provide in your company?",
      name: "main_functionality",
      fields: [
        "It is necessary for managers to quickly respond to customer requests (it is desirable that the system itself controls them)",
        "It is important for management to quickly find out at what stage and what deal is, what work on the deal was carried out, and what is missing, and for what reasons (It is also desirable to automate the control)",
        "We need an end-to-end automatically generated report on the activities of the sales department or other departments (number of letters, calls, etc.)",
        "We need to connect IP telephony so that all conversations are recorded",
        "We need that when calling, the client always gets to his manager",
        "We need a voice menu when calling our number (IVR), (greeting and customize the client's path in directions when an incoming call is pressed by pressing numbers on the phone)",
        "We need to connect corporate mail (with letter templates), as well as personal mail for all employees",
        "We need a history of correspondence with the client and manager by mail along with conversation records (in a single interface)",
        "We need communication in different messengers through one chat for the manager (with the ability to view the dialogue later)",
        "It is important to promptly respond to comments in social networks (facebook, instagram)",
        "Generate documents from the system (invoices, contracts, acts) without filling them out manually",
        "I would like to link all communications within the company between departments and branches, my chats and the company's social network. So that I could quickly clarify and contact everyone, hold a meeting or planning meeting online, including quickly voting between all employees.",
        "It is necessary to speed up the approval of documents for vacations, time off, advances, etc.I would like to discuss specific deals in separate chats, monitor the progress of important deals",
        "I would like to receive operational reports on the state of affairs (how many deals are in progress, how much money has come in, which deals are 'stuck')",
        "I would like to see the workload of employees (who is managing how many clients, how many calls/sent letters, how many new clients each employee has, for example, per month)",
        "We need a system of automatic reminders for employees",
        "We need a system of automatic notifications if an employee has not performed the appropriate actions to conclude a deal/part of its process",
        "We need a system for monitoring the speed of response to customer requests",
        "We need to systematically work with the client base to stimulate repeat sales. For example, select everyone who has not worked with us during the year and call them and then monitor what the result is at the moment",
        "You need an email and SMS marketing tool",
        "You need to select a separate group (segment) from all clients and run advertising for them on social networks or Google",
        "It is necessary to manage projects, distributing tasks between employees, setting deadlines and subsequent reports on the results",
        "Need to schedule tasks",
        "Need a report on time spent on tasks",
        "I would like all data to be quickly accessible, and employees to collect information without fail, and control in the system to carry out each step of work, not allowing the next step without completing the previous one",
        "It is necessary to automate the introduction of employees into work, facilitate the work of HR and the adaptation of new employees",
        "I don’t really understand this and prefer to create technical specifications and conduct consultations on the implementation of a CRM system, as well as business processes, in my company.",
        "I am interested in project management. I have already formed a list of tasks (regulations) and subtasks that must be completed within a strictly allotted time frame, and your goal is the implementation of these tasks. (write - attach file)",
        "We already have CRM as a tool, but we can’t implement it on our own. There is a need for employee training and consultations on CRM capabilities to obtain maximum effect",
      ],
    },
    {
      title: "Where will your CRM data be stored?",
      desc: "Cloud/Boxed version",
      name: "crm_data",
      fields: [
        "In the cloud",
        "On the organization's server (Cloud/physical)",
        "Ready to consider recommendations",
      ],
    },
    {
      title: "How urgently do you need to solve CRM system integration tasks?",
      name: "urgently_solve_system",
      fields: [
        "It is more important for me to understand what a CRM system is in more detail, what it can give me, and only after that I can give an answer about the urgency",
        "Not urgent",
        "It is desirable to do everything in the near future (1-3 months)",
        "Our company urgently needs a CRM system",
      ],
    },
  ],
  uz: [
    {
      title: "Savdolaringizning asosiy qismi ulgurji yoki chakana hisoblanadi",
      name: "sales",
      fields: [
        "Ulgurji",
        "Chakana savdo",
        "Ulgurji/chakana savdo",
        "Xizmat ko'rsatish",
        "Loyihalar bilan ishlash",
      ],
    },
    {
      title: "Kompaniyangizning asosiy mijozlari",
      name: "clients",
      fields: [
        "Jismoniy shaxslar - B2C",
        "Yuridik shaxslar - B2B",
        "Ikkala yo'nalish ham",
      ],
    },
    {
      title: "Sizning kompaniyangiz bozorda necha yildan beri ishlaydi?",
      name: "market",
      fields: ["0-6 oy", "2 yilgacha", "2-5 yil", "5 yildan ortiq"],
    },
    {
      title: "Siz hozirda biron bir CRM yoki ERP tizimini joriy qildingizmi?",
      name: "system",
      desc: "Masalan, 1C, Bitrix va boshqalar",
      fields: ["Ha", "Yo'q"],
      input: {
        title: "Ha bo'lsa, qaysi biri?",
        placeholder: "Platformaning turi va toifasini belgilang",
      },
    },
    {
      title:
        "Kompaniyaning boshqaruv jamoasini hisobga olgan holda CRM tizimidan qancha kompaniya xodimlari foydalanadi?",
      desc: "Tizim tomonidan bajariladigan vazifalarning funksionalligi va murakkabligi foydalanuvchilar soniga bog'liq.",
      name: "number_of_employees",
      fields: ["1 - 5", "5 - 20", "20 - 50", "50 - 100", "100+"],
    },
    {
      title: "Kompaniyangizning qaysi bo'limlari CRM tizimidan foydalanadi?",
      name: "departments",
      fields: [
        "Tashkilot boshqaruvi",
        "Savdo bo'limi",
        "Inson resurslari bo'limi",
        "Moliya bo'limi, buxgalteriya",
        "Yuridik bo'lim",
        "Marketing bo'limi",
        "Rivojlanish markazi",
        "Tizim boshqaruvi bo'limi",
        "Sanoat majmuasi",
        "Ta'minot va logistika bo'limi",
        "Tahlil bo'limi",
        "Sifat monitoringi bo'limi",
      ],
    },
    {
      title:
        "CRM tizimidan foydalanadigan kompaniya xodimlari ishlaydigan ofislar, omborlar, do'konlar, ishlab chiqarish ustaxonalarining umumiy soni qancha?",
      name: "total_number_of_offices",
      fields: ["Ular yo'q", "1-2", "2-5", "5-15", "15+"],
    },
    {
      title:
        "CRM tizimidan foydalanadigan kompaniya xodimlari ishlaydigan ofislar, omborlar, do'konlar, ishlab chiqarish ustaxonalari joylashuvi",
      name: "location_of_offices",
      fields: ["Bir shaharda", "Turli shaharlarda", "Turli mamlakatlarda"],
    },
    {
      title:
        "Ro'yxatdan kompaniyangizda CRM tizimi taqdim etishi kerak bo'lgan asosiy funksiyani tanlang?",
      name: "main_functionality",
      fields: [
        "Menejerlar mijozlarning so'rovlariga tezda javob berishlari kerak (tizimning o'zi ularni boshqarishi maqsadga muvofiqdir)",
        "Rahbariyat operatsiya qaysi bosqichda va nima ekanligini, tranzaktsiya bo'yicha qanday ishlar amalga oshirilganligini va nima etishmayotganligini va qanday sabablarga ko'ra tezda bilib olishi muhimdir (nazoratni avtomatlashtirish ham maqsadga muvofiqdir)",
        "Savdo bo'limi yoki boshqa bo'limlar faoliyati to'g'risida avtomatik ravishda yaratiladigan yakuniy hisobot (chaqiruv xatlari soni va boshqalar) kerak.",
        "Barcha suhbatlar yozib olinishi uchun siz IP-telefoniyani ulashingiz kerak",
        "Mijoz qo'ng'iroq qilganda har doim o'z menejeriga etib borishi kerak",
        "Bizning raqamimizga (IVR) qo'ng'iroq qilishda bizga ovozli menyu kerak (telefondagi raqamlarni bosish orqali kirish qo'ng'irog'i uchun mijozning yo'nalishini kutib olish va sozlash)",
        "Siz korporativ elektron pochtani (xat shablonlari bilan), shuningdek, barcha xodimlar uchun shaxsiy elektron pochtani ulashingiz kerak",
        "Bizga mijoz va menejer bilan elektron pochta orqali yozishmalar tarixi va suhbat yozuvlari (bitta interfeysda) kerak.",
        "Menejer uchun bitta chat orqali turli messenjerlarda muloqot qilish kerak (dialogni keyinroq ko'rish imkoniyati bilan)",
        "Ijtimoiy tarmoqlardagi sharhlarga tezda javob berish muhimdir. tarmoqlar (facebook, instagram)",
        "Tizimdan hujjatlarni (shartnoma hisob-fakturalari, aktlar) qo'lda to'ldirmasdan yarating",
        "Men kompaniya ichidagi barcha aloqalarni bo'limlar va filiallar, chatlarim va kompaniyaning ijtimoiy tarmog'i o'rtasida bog'lashni xohlayman. Shunday qilib, siz tezda aniqlik kiritishingiz va hamma bilan bog'lanishingiz, onlayn uchrashuv yoki rejalashtirish uchrashuvini o'tkazishingiz, shu jumladan barcha xodimlar o'rtasida tezkor ovoz berishingiz mumkin.",
        "Ta'tillar, dam olish vaqti, avanslar va boshqalar uchun hujjatlarni tasdiqlashni tezlashtirish kerak.Men alohida chatlarda muayyan tranzaktsiyalarni muhokama qilishni, muhim tranzaktsiyalarning borishini kuzatishni xohlayman",
        "Men ishlarning holati to'g'risida tezkor hisobotlarni olishni xohlayman (qancha tranzaktsiyalar amalga oshirilayotgani, qancha pul tushganligi, qaysi tranzaktsiyalar to'xtab qolganligi)",
        "Men xodimlarning ish yukini ko'rishni xohlayman (qancha mijozni kim boshqaradi, ular qancha qo'ng'iroqlar/elektron pochta jo'natishgan, har bir xodimda, masalan, oyiga qancha yangi mijozlar bor)",
        "Bizga xodimlar uchun avtomatik eslatmalar tizimi kerak",
        "Agar xodim bitimni/uning jarayonining bir qismini tuzish uchun tegishli harakatlarni bajarmagan bo'lsa, avtomatik bildirishnomalar tizimi kerak.",
        "Bizga mijozlar so'rovlariga javob berish tezligini nazorat qilish tizimi kerak",
        "Takroriy savdoni rag'batlantirish uchun mijozlar bazasi bilan tizimli ravishda ishlash kerak.Masalan, yil davomida biz bilan ishlamagan har bir kishini tanlang va ularga qo'ng'iroq qiling, so'ngra hozir qanday natija borligini kuzatib boring)",
        "Sizga elektron pochta va SMS marketing vositasi kerak",
        "Siz barcha mijozlardan alohida guruhni (segmentni) tanlashingiz va ular uchun ijtimoiy tarmoqlarda yoki Googleda reklama qilishingiz kerak.",
        "Loyihalarni boshqarish, xodimlar o'rtasida vazifalarni taqsimlash, muddatlarni belgilash va natijalar bo'yicha keyingi hisobotlarni tayyorlash kerak.",
        "Vazifalarni rejalashtirish kerak",
        "Vazifalarga sarflangan vaqt haqida hisobot kerak",
        "Men ish jarayonlarining bir qismini avtomatlashtirish orqali odatdagi harakatlarni olib tashlash orqali xodimlarni engillashtirmoqchiman.",
        "Men barcha ma'lumotlarga tezda kirishni va xodimlarning ma'lumot to'plashini va tizimda ishning har bir bosqichini amalga oshirishni nazorat qilishni, avvalgisini tugatmasdan keyingi bosqichga yo'l qo'ymaslikni xohlayman.",
        "Xodimlarni ishga kiritishni avtomatlashtirish, HR ishini osonlashtirish va yangi xodimlarni moslashtirish zarur.",
        "Men buni haqiqatan ham tushunmayapman va o'z kompaniyamda texnik xususiyatlarni yaratishni va CRM tizimini, shuningdek biznes jarayonlarini joriy etish bo'yicha maslahatlar o'tkazishni afzal ko'raman.",
        "Men loyihani boshqarishga qiziqaman, men allaqachon belgilangan vaqt oralig'ida bajarilishi kerak bo'lgan vazifalar (reglamentlar) va kichik vazifalar ro'yxatini tuzganman va sizning maqsadingiz ushbu vazifalarni amalga oshirishdir. (yozish - faylni biriktirish)",
        "Bizda CRM allaqachon vosita sifatida mavjud, ammo biz uni o'zimiz amalga oshira olmaymiz. Maksimal samaraga erishish uchun xodimlarni o'qitish va CRM imkoniyatlari bo'yicha maslahatlarga ehtiyoj bor",
      ],
    },
    {
      title: "CRM ma'lumotlaringiz qayerda saqlanadi?",
      desc: "Bulutli/qutili versiya",
      name: "crm_data",
      fields: [
        "Bulutda",
        "Tashkilot serverida (bulutli/jismoniy)",
        "Tavsiyalarni ko'rib chiqishga tayyor",
      ],
    },
    {
      title:
        "CRM tizimini integratsiyalash muammolarini qanchalik zudlik bilan hal qilishingiz kerak?",
      name: "urgently_solve_system",
      fields: [
        "Men uchun CRM tizimi nima ekanligini, u menga nima berishi mumkinligini batafsilroq tushunish muhimroq va shundan keyingina men shoshilinchlik haqida javob berishim mumkin.",
        "Shoshmang",
        "Yaqin kelajakda hamma narsani qilish tavsiya etiladi (1-3 oy)",
        "Kompaniyamizga zudlik bilan CRM tizimi kerak",
      ],
    },
  ],
  ru: [
    {
      title: "Основная часть Ваших продаж - это опт или розница",
      name: "sales",
      fields: [
        "Оптовые",
        "Розничные",
        "Опт/Розница",
        "Оказание услуг",
        "Работа с проектами",
      ],
    },
    {
      title: "Основные клиенты Вашей компании",
      name: "clients",
      fields: ["Физ.лица - B2C", "Юр.лица - B2B", "Оба направления"],
    },
    {
      title: "Сколько лет Ваша компания на рынке?",
      name: "market",
      fields: ["0-6 месяцев", "До 2-х лет", "2-5 лет", "Более 5 лет"],
    },
    {
      title: "Внедрена ли у Вас какая-то CRM или ERP-система на данный момент?",
      name: "system",
      desc: "Например 1С, Битрикс и иные",
      fields: ["Да", "Нет"],
      input: {
        title: "Если да, то какая",
        placeholder: "Укажите вид и категорию платформы",
      },
    },
    {
      title:
        "Сколько сотрудников компании будут использовать CRM-систему с учетом руководящего состава компании?",
      desc: "От количества пользователей зависит функционал и комплексность задач выполняемых системой",
      name: "number_of_employees",
      fields: ["1 - 5", "5 - 20", "20 - 50", "50 - 100", "100+"],
    },
    {
      title: "Какие отделы Вашей компании будут использовать CRM-систему?",
      name: "departments",
      fields: [
        "Руководство организации",
        "Отдел продаж",
        "Отдел управления персоналом",
        "Фин.отдел, бухгалтерия",
        "Юридический отдел",
        "Отдел маркетинга",
        "Отдел развития",
        "Отдел системного администрирования",
        "Производственный комплекс",
        "Отдел снабжения и логистики",
        "Отдел Аналитики",
        "Отдел мониторинга качества",
      ],
    },
    {
      title:
        "Какое у Вас общее количество офисов, складов, магазинов, производственных цехов, где работают сотрудники компании, которые будут использовать CRM-систему?",
      name: "total_number_of_offices",
      fields: ["Их нет", "1-2", "2-5", "5-15", "15+"],
    },
    {
      title:
        "Расположение офисов, складов, магазинов, производственных цехов, где работают сотрудники компании, которые будут использовать CRM-систему",
      name: "location_of_offices",
      fields: ["В одном городе", "В разных городах", "В разных странах"],
    },
    {
      title:
        "Выберите из списка основной функционал, который должна предоставлять CRM-система в Вашей компании?",
      name: "main_functionality",
      fields: [
        "Необходимо,  чтоб менеджеры быстро реагировали на обращения клиентов (желательно, чтобы система сама контролировала их)",
        "Руководству важно быстро узнать, на какой стадии и какая сделка находится, какие работы по сделке были проведены, и каких не хватает, и по каким причинам (Также желательно автоматизировать контроль)",
        "Нужен сквозной автоматически генерируемый отчет по деятельности отдела продаж или других отделов (количество писем звонков и т.д.)",
        "Нужно подключить IP-телефонию, чтобы велись записи всех разговоров",
        "Нужно, чтобы при звонке клиент всегда попадал на своего менеджера",
        "Нужно голосовое меню при звонке на наш номер (IVR), (приветствие и настроить путь клиента по направлениям при входящем звонке по нажатию цифр на телефоне)",
        "Нужно подключить корпоративную почту (с шаблонами писем), а также личную почту всем сотрудникам",
        "Нужна история переписки с клиентом и менеджером по почте вместе с записями разговоров (в едином интерфейсе)",
        "Нужно общение в разных мессенджерах через один чат для менеджера (с возможностью просмотреть диалог позже)",
        "Важно оперативно реагировать на комментарии в соц. сетях (facebook, instagram)",
        "Генерировать документы из системы (счета договора, акты), не заполняя их вручную",
        "Хотелось бы связать все коммуникации внутри компании между отделами и филиалами, свои чаты и социальная сеть компании. Чтобы можно было быстро уточнить и обратиться к каждому, провести собрание или планерку онлайн, в том числе быстро проводить голосование между всеми сотрудниками.",
        "Нужно ускорить согласование документов на отпуски, отгулы, авансы и т.п.",
        "Хотелось бы обсуждать конкретные сделки в отдельных чатах, наблюдать за ходом важных сделок",
        "Хотелось бы получать оперативные отчеты по состоянию дел (сколько сделок находится в работе, сколько средств зашло, какие сделки 'зависли')",
        "Хотелось бы видеть нагрузку на сотрудников (кто сколько клиентов ведет, сколько совершил звонков/отослал писем, сколько новых клиентов за каждым сотрудником, например за месяц)",
        "Нужна система автоматических напоминаний сотрудникам",
        "Нужна система автоматических уведомлений, если сотрудник не выполнил надлежащие действия для заключения сделки/части её процесса",
        "Нужна система контроля скорости ответа на обращения клиентов",
        "Нужно системно работать с клиентской базой для стимуляци повторных продаж. Например, выбрать всех, кто не работал с нами в течение года и обзвонить их с последующим контролем того, какой есть результат на данный момент)",
        "Нужен инструмент email- и SMS-рассылок",
        "Нужно выбирать из всех клиентов отдельную группу (сегмент), и запускать для них рекламу в социальных сетях или Google",
        "Нужно вести проекты, распределяя задачи между сотрудниками с проставлением сроков исполнения и последующими отчетами по результатам",
        "Нужно календарное планирование задач",
        "Нужен отчет по времени, затраченному на задачи",
        "Хотелось бы разгрузить сотрудников, сняв с них рутинные действия посредством автоматизации некой части рабочих процессов",
        "Хотелось бы, чтобы все данные находились в быстром доступе, и сотрудники в обязательном порядке собирали информацию, и контроль в системе осуществлял каждый шаг работы, не допуская к следующему без выполнения предыдущего",
        "Нужно автоматизировать ввод сотрудников в работу, облегчить работу HR и адаптацию новых сотрудников",
        "Я не очень в этом разбираюсь и отдаю предпочтение созданию технического задания и проведению консультаций по внедрению CRM-системы, а также бизнес-процессов в свою компанию.",
        "Меня интересует проектное управление.У меня уже сформированный список задач (регламентов) и подзадач, которые необходимо выполнять в строго отведенные сроки, и Ваша цель - это реализация этих задач. (написать - прикрепить файл)",
        "У нас уже есть CRM, как инструмент, но внедрить его своими силами не выходит. Есть необходимость в обучении сотрудников, консультациях по возможностям CRM для получения максимального эффекта",
      ],
    },
    {
      title: "Где будут храниться данные Вашей CRM?",
      desc: "Облачная/коробочная версия",
      name: "crm_data",
      fields: [
        "В облаке",
        "На сервере организации (Облачный/физический)",
        "Готовы рассмотреть рекомендации",
      ],
    },
    {
      title: "Как срочно Вам нужно решить задачи по интеграции CRM-системы?",
      name: "urgently_solve_system",
      fields: [
        "Для меня важнее понять, что такое CRM-система подробнее, что она может мне дать, и только после этого смогу дать ответ о срочности.",
        "Не срочно",
        "Желательно в ближайшее время всё сделать (1-3 месяца)",
        "Нашей компании срочно нужна CRM-система",
      ],
    },
  ],
};
export const InfoCOntact = {
  en: [
    {
      title:
        "Write in free form what problem areas the company has or what tasks within the company you want to solve with the help of a CRM system (or attach a document with tasks/TOR)",
      fields: [
        {
          title: "Describe problem areas",
          name:'problem',
          placeholder: "",
        },
      ],
    },
    {
      title: "Contact details",
      fields: [
        {
          title: "Email address",
          name:'email',
          placeholder: "Enter e-mail",
        },
        {
          title: "Contact number for communication",
          name:'phone_number',
          placeholder: "Enter phone number for calls and telegrams",
        },
        {
          title: "Company website",
          name:'company_website',
          placeholder: "http://example.com",
        },
      ],
    },
  ],
  uz: [
    {
      title:
        "Kompaniyada qanday muammoli joylar mavjudligini yoki kompaniya ichidagi qanday vazifalarni CRM tizimi yordamida hal qilmoqchi ekanligingizni erkin shaklda yozing (yoki topshiriqlar/TOR bilan hujjatni ilova qiling)",
      fields: [
        {
          title: "Muammoli joylarni tavsiflang",
          name:'problem',
          placeholder: "",
        },
      ],
    },
    {
      title: "Aloqa ma'lumotlarini",
      fields: [
        {
          title: "Elektron pochtangizni kiriting",
          name:'email',
          placeholder: "E-mail manzili",
        },
        {
          title: "Aloqa uchun aloqa raqami",
          name:'phone_number',
          placeholder: "Qo'ng'iroqlar va telegrammalar uchun telefon raqamingizni kiriting",
        },
        {
          title: "Kompaniya veb-sayti",
          name:'company_website',
          placeholder: "http://example.com",
        },
      ],
    },
  ],
  ru: [
    {
      title:
        "Напишите в свободной форме, какие имеются проблемные зоны в компании или какие задачи внутри компании Вы хотите решить с помощью CRM-системы (или прикрепите документ с задачами/ТЗ)",
      fields: [
        {
          title: "Опишите проблемные зоны",
          name:'problem',
          placeholder: "",
        },
      ],
    },
    {
      title: "Контактные данные",
      fields: [
        {
          title: "Адрес электронной почты",
          name:'email',
          placeholder: "Укажите e-mail",
        },
        {
          title: "Контактный номер для связи",
          name:'phone_number',
          placeholder: "Укажите номер телефона для звонков и телеграм",
        },
        {
          title: "Вебсайт компании",
          name:'company_website',
          placeholder: "http://example.com",
        },
      ],
    },
  ],
};
export const InfoCompanyMobile ={
  ru:{
    title: "Информация о компании",
    fields: [
      {
        title: "Полное название организации",
        name: "full_name_of_organization"
      },
      {
        title: "Описание основных продуктов/услуг",
        name: "description_of_main_products_services"
      }
    ]
  },
  en:{
    title: "Information about the company",
    fields: [
      {
        title: "Full name of the organization",
        name: "full_name_of_organization"
      },
      {
        title: "Description of main products/services",
        name: "description_of_main_products_services"
      }
    ]
  },
  uz:{
    title: "Kompaniya haqida ma'lumot",
    fields: [
      {
        title: "Tashkilotning to'liq nomi",
        name: "full_name_of_organization"
      },
      {
        title: "Asosiy mahsulotlar/xizmatlar ta'rifi",
        name: "description_of_main_products_services"
      }
    ]
  }
}
export const InfoMobileUser = {
  en:[
    {
      title:'Client:',
      name:'client',
    },
    {
      title:'Date:',
      name:'date',
    },
    {
      title:'The contact person:',
      name:'contact_person',
    },
    {
      title:'Phone number:',
      name:'phone_number',
    },
    {
      title:'Email:',
      name:'email',
    },
    {
      title:'Website:',
      name:'website',
    },
  ],
  uz:[
    {
      title:'Mijoz:',
      name:'client',
    },
    {
      title:'Sana:',
      name:'date',
    },
    {
      title:'Aloqa qiluvchi shaxs:',
      name:'contact_person',
    },
    {
      title:'Telefon raqam:',
      name:'phone_number',
    },
    {
      title:'Email:',
      name:'email',
    },
    {
      title:'Veb-sayt:',
      name:'website',
    },
  ],
  ru:[
    {
      title:'Клиент:',
      name:'client',
    },
    {
      title:'Дата:',
      name:'date',
    },
    {
      title:'Контактное лицо:',
      name:'contact_person',
    },
    {
      title:'Телефоны:',
      name:'phone_number',
    },
    {
      title:'E – mail:',
      name:'email',
    },
    {
      title:'Сайт:',
      name:'website',
    },
  ],
}
export const InfoMobile = {
  en:{
    title: "Information about the mobile application",
    fields: [
      {
        title: "What prompted you to decide to create a new application (change the existing one)?",
        name: "reason_for_creating_new_app"
      },
      {
        title: "What goals do you want to achieve with the help of the mobile application?",
        name: "goals_of_mobile_application"
      },
      {
        title: "The main users and their actions in the mobile application",
        name: "main_users_and_actions"
      },
      {
        title: "Similar applications",
        name: "similar_applications"
      },
      {
        title: "What type of application needs to be developed? (Corporate applications, internet stores, games, information or educational applications, etc.)",
        name: "type_of_application"
      },
      {
        title: "Write the preliminary structure of the application: main sections, subsections. Briefly describe their functional purpose and give the characteristics of the content of each section",
        name: "preliminary_structure"
      },
      {
        title: "For which mobile platforms should the application be developed?",
        name: "target_platforms"
      },
      {
        title: "Will the application interact with other services and systems, for example, payment systems?",
        name: "interaction_with_other_services"
      },
      {
        title: "What is the project budget (development, promotion within the project, etc.)?",
        name: "project_budget"
      },
      {
        title: "Do you need any additional materials from our side? (For example, 3D, video, graphics, etc.)",
        name: "additional_materials_needed"
      },
      {
        title: "Desired terms of application development",
        name: "desired_development_timeline"
      }
    ]
  },
  ru:{
    title: "Информация о мобильном приложении",
    fields: [
      {
        title: "Что побудило Вас к решению создать новое приложение (изменить существующее)?",
        name: "reason_for_creating_new_app"
      },
      {
        title: "Каких целей Вы хотите достичь с помощью мобильного приложения?",
        name: "goals_of_mobile_application"
      },
      {
        title: "Основные пользователи и их действия в мобильном приложении",
        name: "main_users_and_actions"
      },
      {
        title: "Аналоги/конкуренты приложения",
        name: "similar_applications"
      },
      {
        title: "Какой тип приложения требуется разработать? (Корпоративное приложение, интернет-магазин, игра, информационное или обучающее приложение и т.д.)",
        name: "type_of_application"
      },
      {
        title: "Напишите предварительную структуру приложения: основные разделы, подразделы. Кратко опишите их функциональное назначение и дайте характеристики содержания каждого из разделов",
        name: "preliminary_structure"
      },
      {
        title: "Под какие мобильные платформы разрабатывается приложение? (iOS, Android)",
        name: "target_platforms"
      },
      {
        title: "Будет ли интеграция с другими сервисами и системами, например, платежными?",
        name: "interaction_with_other_services"
      },
      {
        title: "Предусматривается ли монетизация на проекте, и в каких формах она будет заключаться?",
        name: "monetization"
      },
      {
        title: "Необходимы ли от вас какие-либо дополнительные материалы? Например, 3D, видео, изображения и т.д.",
        name: "additional_materials_needed"
      },
      {
        title: "Желаемые сроки разработки приложения",
        name: "desired_development_timeline"
      },
      {
        title: "Бюджет проекта (разработка, продвижение в рамках др. и др.)",
        name: "project_budget"
      }
    ]
  },
  uz:{
    "title": "Mobil ilova haqida ma'lumot",
    "fields": [
      {
        "title": "Yangi ilovani yaratishga (yoki mavjudini o'zgartirishga) nima sabab bo'ldi?",
        "name": "reason_for_creating_new_app"
      },
      {
        "title": "Mobil ilova orqali qanday maqsadlarga erishmoqchisiz?",
        "name": "goals_of_mobile_application"
      },
      {
        "title": "Ilovaning asosiy foydalanuvchilari va ularning harakatlari",
        "name": "main_users_and_actions"
      },
      {
        "title": "Ilovaning analoglari/raqobatchilari",
        "name": "similar_applications"
      },
      {
        "title": "Qanday turdagi ilova ishlab chiqilishi kerak? (Korporativ ilova, internet-do'kon, o'yin, ma'lumot yoki o'quv ilova va boshqalar)",
        "name": "type_of_application"
      },
      {
        "title": "Ilovaning dastlabki tuzilmasini yozing: asosiy bo'limlar, bo'limlar. Ularning funktsional maqsadini qisqacha tasvirlab bering va har bir bo'limning tarkibini ta'riflang",
        "name": "preliminary_structure"
      },
      {
        "title": "Ilova qaysi mobil platformalar uchun ishlab chiqilmoqda? (iOS, Android)",
        "name": "target_platforms"
      },
      {
        "title": "Boshqa xizmatlar va tizimlar bilan integratsiya bo'ladimi, masalan, to'lov tizimlari bilan?",
        "name": "interaction_with_other_services"
      },
      {
        "title": "Loyihada monetizatsiya ko'zda tutilganmi va qanday shakllarda amalga oshiriladi?",
        "name": "monetization"
      },
      {
        "title": "Sizdan qo'shimcha materiallar kerak bo'ladimi? Masalan, 3D, video, tasvirlar va boshqalar.",
        "name": "additional_materials_needed"
      },
      {
        "title": "Ilovaning ishlab chiqilishi uchun istalgan muddatlar",
        "name": "desired_development_timeline"
      },
      {
        "title": "Loyihaning byudjeti (ishlab chiqish, reklama va boshqalar)",
        "name": "project_budget"
      }
    ]
  }
}

export const InfoCompFree = {
  en: {
    fields: [
      {
        title:
          "Name",
          name:'name',
      },
      {
        title:
          "Phone number",
          name:'phone_number',
      },
      {
        title:
          "Please feel in a description on needed services",
          name:'desc',
      },
    ],
  },
  uz: {
    fields: [
      {
        title:
          "Ism",
          name:'name',
      },
      {
        title:
          "Telefon raqam",
          name:'phone_number',
      },
      {
        title:
          "Iltimos, kerakli xizmatlarning tavsifida o'zingizni his qiling",
          name:'desc',
      },
    ],
  },
  ru: {
    fields: [
      {
        title:
          "Имя",
          name:'name',
      },
      {
        title:
          "Номер телефона",
          name:'phone_number',
      },
      {
        title:
          "Пожалуйста, укажите в описании необходимые услуги.",
          name:'desc',
      },
    ],
  },
};