import { useSelector } from "react-redux";
import {
  InfoMobileUser,
  InfoCompanyMobile,
  InfoMobile,
} from "../../assets/data";
import React from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from 'yup'
import axios from "axios";

const Mobile = () => {
  const lang = useSelector((state) => state.data.lang);
  const validation = Yup.object().shape({
    full_name_of_organization: Yup.string().required("Required"),
    description_of_main_products_services: Yup.string().required("Required"),
    client: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    contact_person: Yup.string().required("Required"),
    phone_number: Yup.number("Invalid number").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    website: Yup.string().required("Required"),
    reason_for_creating_new_app: Yup.string().required("Required"),
    goals_of_mobile_application: Yup.string().required("Required"),
    main_users_and_actions: Yup.string().required("Required"),
    similar_applications: Yup.string().required("Required"),
    type_of_application: Yup.string().required("Required"),
    preliminary_structure: Yup.string().required("Required"),
    target_platforms: Yup.string().required("Required"),
    interaction_with_other_services: Yup.string().required("Required"),
    project_budget: Yup.string().required("Required"),
    additional_materials_needed: Yup.string().required("Required"),
    desired_development_timeline: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          full_name_of_organization: "",
          description_of_main_products_services: "",
          client: "",
          date: "",
          contact_person: "",
          phone_number: "",
          email: "",
          website: "",
          reason_for_creating_new_app: "",
          goals_of_mobile_application: "",
          main_users_and_actions: "",
          similar_applications: "",
          type_of_application: "",
          preliminary_structure: "",
          target_platforms: "",
          interaction_with_other_services: "",
          project_budget: "",
          additional_materials_needed: "",
          desired_development_timeline: "",
        }}
        validationSchema={validation}
        onSubmit={(values) => {
          const formdata = new FormData();
          Object.keys(values).forEach((elem) => {
            formdata.append(elem, values[elem]);
          });
          toast.promise(
            axios.post(
              "https://script.google.com/macros/s/AKfycbzlwLzrgM4ofeYcQ5PnqVF3pEltlS2CSR9J3kDR1ht5mJW35p-obdCjtA9ZqBM8VCR4xg/exec",
              formdata,
              { headers: { "Content-Type": "multipart/form-data" } }
            ),
            {
              pending: "Request is pending...",
              success: "Data posted successfully!",
              error: "Error fetching data!",
            }
          );
        }}
      >
        {({ values,setFieldValue,errors,onSubmit }) => {
          return (
            <Form>
              <>
                <div className="ticket_block_botton">
                  {InfoMobileUser[lang]?.map((elem, i) => (
                    <React.Fragment key={elem.title}>
                      <br />
                      <p className="ticket_block_bottom_description">
                        {elem?.title}
                      </p>
                      <br />
                      <input
                        type="text"
                        name={elem?.name}
                        onChange={(e)=>setFieldValue(elem?.name, e.target.value)}
                        placeholder={elem?.placeholder}
                        className="ticket_block_bottom_input"
                      />
                      <p className="error" >{errors[elem?.name] && errors[elem?.name]}</p>
                      <br />
                    </React.Fragment>
                  ))}
                </div>
                <div className="ticket_block_botton">
                  <h2 className="ticket_block_bottom_title">
                    {InfoCompanyMobile[lang]?.title} *
                  </h2>
                  {InfoCompanyMobile[lang]?.fields?.map((elem, i) => (
                    <React.Fragment key={elem.title}>
                      <br />
                      <p className="ticket_block_bottom_description">
                        {elem?.title}
                      </p>
                      <br />
                      <input
                        type="text"
                        name={elem?.name}
                        onChange={(e)=>setFieldValue(elem?.name,e.target.value)}
                        placeholder={elem?.placeholder}
                        className="ticket_block_bottom_input"
                      />
                      <p className="error" >{errors[elem?.name] && errors[elem?.name]}</p>
                      <br />
                    </React.Fragment>
                  ))}
                </div>
                <div className="ticket_block_botton">
                  <h2 className="ticket_block_bottom_title">
                    {InfoMobile[lang]?.title} *
                  </h2>
                  {InfoMobile[lang]?.fields?.map((elem, i) => (
                    <React.Fragment key={elem.title}>
                      <br />
                      <p className="ticket_block_bottom_description">
                        {elem?.title}
                      </p>
                      <br />
                      <input
                        type="text"
                        name={elem?.name}
                        onChange={(e)=>setFieldValue(elem?.name,e.target.value)}
                        placeholder={elem?.placeholder}
                        className="ticket_block_bottom_input"
                      />
                      <p className="error" >{errors[elem?.name] && errors[elem?.name]}</p>
                      <br />
                    </React.Fragment>
                  ))}
                </div>
                <button onClick={onSubmit} className="tickent_btn">
                {lang == "en"
                  ? "Send"
                  : lang == "ru"
                  ? "Отправить"
                  : "Yuborish"}
              </button>
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Mobile;
