import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { request } from "../../api/request";
import ServerCard1 from "../home/our-server-card copy";

export default function ServiceSlider(params) {
  const { setOurService, ourService } = params;
  const lang = useSelector((state) => state.data.lang);
  const [home4, setHome4] = useState({
    isFetched: false,
    data: {},
    error: null,
  });
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#3D3FA3" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#3D3FA3" }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 808,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    request
      .get(`/content/all/service?lang=${lang}&sort=asc&sortby=sort`)
      .then(function (res) {
        setHome4({ isFetched: true, data: res.data.data, error: false });
      })
      .catch(function (err) {
        setHome4({ isFetched: true, data: [], error: err });
      });
  }, [lang]);
  return (
    <div>
      <div data-aos="flip-up">
        <div className="sponsor">
          <Slider {...settings}>
            {home4.isFetched ? (
              home4.data.map((item, index) => (
                <ServerCard1
                  setOurService={setOurService}
                  ourService={ourService}
                  id={item?.id}
                  icon={item.image}
                  name={item.title}
                  info={item.description}
                  key={item?.id}
                />
              ))
            ) : (
              <></>
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
}
