import { useSelector } from "react-redux";
import Ermerp from "../components/ermerp";
import { useState } from "react";
import Mobile from "../components/mobile";
import Free from "../components/free";
const Ticket = () => {
  const lang = useSelector((state) => state.data.lang);
  const [type, setType] = useState("erm");
  const btns =  {
    en:[
      {
        name: "ERM/ERP",
        type: "erm",
      },
      {
        name: "Mobile",
        type: "mobile",
      },
      {
        name: "Free form",
        type: "free",
      },
    ],
    ru:[
      {
        name: "ERM/ERP",
        type: "erm",
      },
      {
        name: "Мобильный",
        type: "mobile",
      },
      {
        name: "Свободная форма",
        type: "free",
      },
    ],
    uz:[
      {
        name: "ERM/ERP",
        type: "erm",
      },
      {
        name: "Mobil",
        type: "mobile",
      },
      {
        name: "Erkin shakl",
        type: "free",
      },
    ]
  
  }
    
  return (
    <div className="ticket container">
      <div className="filter-btn">
        {btns[lang]?.map((elem) => (
          <button
            className={type == elem?.type ? "selected" : ""}
            key={elem}
            onClick={() => setType(elem?.type)}
          >
            {elem?.name}
          </button>
        ))}
      </div>
      {type == "erm" ? <Ermerp /> : type == "mobile" ? <Mobile /> : <Free />}
    </div>
  );
};

export default Ticket;
